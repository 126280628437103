



























































































































































































































































































































































































































































































import {
  defineComponent,
  useRoute,
  watch,
  ref,
  useContext,
  ComputedRef,
  computed,
  useRouter
} from '@nuxtjs/composition-api';
import { NavigationItem, GLOBAL_MENU, USER_TYPE } from '@/configs';
import { useStore } from '@/compositions/store';
import { userStore, appStore } from '@/store';
import { requirePermission } from '@/utils/filter';
import ApplicationBar from '@/components/layout/applicationbar.vue';
import icnextApplicationModal from '@/components/icnext/application.vue';
import { StringKeyObject } from '@/types';
import {
  openFukuri,
  openPayment,
  testBar,
  isShowCampfireNewBadge,
  isShowBusinessSuccessionNewBadge,
  isShowIcnextLinkageNewBadge,
  isShowPaymentNewBadge
} from '@/utils';
import NavigationMainButton from '@/components/layout/navigation-main-button.vue';
import NavigationSubButton from '@/components/layout/navigation-sub-button.vue';
import UserProfile from '@/components/layout/user-profile.vue';
import ApplicationBarToggleButton from '@/components/layout/applicationbar-toggle-button.vue';
import FooterNavi from '@/components/layout/footer-navi.vue';
import SpSettingDrawer from '@/components/layout/sp-setting-drawer.vue';
import { isMainItemSelected, isSubItemSelected } from '@/compositions/sidenavi';

export default defineComponent({
  name: 'GlobalNavigation',
  components: {
    SpSettingDrawer,
    FooterNavi,
    ApplicationBarToggleButton,
    UserProfile,
    NavigationSubButton,
    NavigationMainButton,
    ApplicationBar,
    icnextApplicationModal
  },
  setup() {
    const {
      isAdmin,
      mobileNavi,
      unreadCmiCount,
      bachatRoomsUnreadCount,
      chatRoomsUnreadCount,
      matchingUnrepliedCount,
      setOpenSC,
      openSC,
      device,
      icnextDisplayModal,
      setIcnextModal
    } = useStore();

    const items = ref<NavigationItem[]>([]);
    const route = useRoute();
    const router = useRouter();
    let adjustment = testBar() ? 'margin-top: 66px;' : 'margin-top: 54px;';
    const drawer = ref<boolean>(true);
    const spSettingDrawer = ref(false);
    const currentPath = computed(() => route.value.path);
    const currentHash = computed(() => route.value.hash);

    let skipFukuri = false;

    /**
     * 選択中のメインメニュー項目のサブメニューを取得する
     */
    const currentSubItems: ComputedRef<NavigationItem[]> = computed(() => {
      const item = items.value.find((item: NavigationItem) =>
        isMainItemSelected(item, currentPath.value, currentHash.value)
      );
      return item?.sub ?? [];
    });

    /**
     * メニュータイトルからサブメニューのリストを取得する
     * @param title
     */
    const getSubItems = (title: string): NavigationItem[] => {
      const item = items.value.find(
        (item: NavigationItem) => item.title === title
      );
      return item?.sub ? item.sub : [];
    };

    // メインナビの幅
    const mainNaviWidth = {
      pc: 85,
      tablet: 100
    } as const;
    // サブナビの幅
    const subNaviWidth = {
      pc: 220,
      tablet: 380
    } as const;

    const setNavi = (): void => {
      // PCより小さい場合はデフォルトでサイドメニューを表示しない
      drawer.value = device.value === 'pc';
      if (appStore.appConfig.is_yappli_client) {
        adjustment = 'margin-top: 0';
      }
      GLOBAL_MENU.forEach(function (value: NavigationItem) {
        // ユーザータイプチェック
        if (value.user_type) {
          if (value.user_type_exclude) {
            if (value.user_type.includes(userStore.loginUser.user_type)) {
              return;
            }
          } else if (!value.user_type.includes(userStore.loginUser.user_type)) {
            return;
          }
        }
        // 企業タイプチェック
        if (value.company_type) {
          if (value.company_type.startsWith('!')) {
            if (
              userStore.loginUser.company_type === value.company_type.slice(1)
            ) {
              return;
            }
          } else if (userStore.loginUser.company_type !== value.company_type) {
            return;
          }
        }
        // ちゃんと請求書(payment)
        if (
          !appStore.appConfig.enable_payment &&
          !appStore.appConfig.enable_payment_receive &&
          value.title === 'ちゃんと請求書'
        ) {
          return;
        }
        // 勤怠サービスが無効
        if (
          !appStore.appConfig.enable_icnext &&
          value.title === '勤怠サービス'
        ) {
          return;
        }
        // 必要な権限が設定されてないおらずサブメニューが存在しない場合
        if (!value.required && !value.sub) {
          items.value.push(value);
          return;
        }
        // 福利厚生
        if (skipFukuri && value.title === '福利厚生') {
          return;
        }
        // クラウドファンディングが無効
        if (
          !appStore.appConfig.enable_campfire &&
          value.title === 'クラウドファンディング'
        ) {
          return;
        }
        // 勤怠サービスが有効になっている場合
        if (value.title === '勤怠サービス') {
          const sub: NavigationItem[] = [];
          value.sub?.forEach(function (subValue: any) {
            if (subValue.user_type?.includes(userStore.loginUser.user_type)) {
              sub.push(subValue);
            }
          });
          value.sub = sub;
        }
        // 事業承継(オーナーだった場合のみ)が無効
        if (
          !appStore.appConfig.enable_business_succession &&
          value.title === '事業承継'
        ) {
          return;
        }
        // 必要な権限を満たしているもしくは必要な権限がない
        if (
          (value.required && requirePermission(value.required)) ||
          !value.required
        ) {
          if (!value.sub) {
            items.value.push(value);
          } else {
            const sub: NavigationItem[] = [];
            value.sub.forEach(function (subValue: any) {
              // 本番環境でスイッチユーザーはビジネスチャットを表示しない
              // https://github.com/kokopelli-inc/bigadvance/blob/6a3bb12b78a96ccb4d58bec9800563bd0fe3d7fe/src/xba/packages/xbacore/classes/controller/api/v1/user.php#L527-L532
              if (userStore.disableChat && subValue.title === 'ビジネスチャット') {
                return;
              }
              // ユーザータイプチェック
              if (subValue.user_type) {
                if (subValue.user_type_exclude) {
                  if (
                    subValue.user_type.includes(userStore.loginUser.user_type)
                  ) {
                    return;
                  }
                } else if (
                  !subValue.user_type.includes(userStore.loginUser.user_type)
                ) {
                  return;
                }
              }
              // 企業タイプチェック
              if (subValue.company_type) {
                if (subValue.company_type.startsWith('!')) {
                  if (
                    userStore.loginUser.company_type ===
                    subValue.company_type.slice(1)
                  ) {
                    return;
                  }
                } else if (
                  userStore.loginUser.company_type !== subValue.company_type
                ) {
                  return;
                }
              }
              // ビジネスユーザー設定
              if (
                subValue.path === '/mypage/setting/account/businessuser' &&
                hasPermission(subValue.required)
              ) {
                if (appStore.appConfig.enable_business_user) {
                  sub.push(subValue);
                }
                // インボイス設定が無効
              } else if (
                subValue.path === '/mypage/setting/invoice' &&
                !appStore.appConfig.available_invoice
              ) {
                // eslint-disable-next-line
                return false;
              } else if (
                // 補助金申請支援サービス
                !appStore.appConfig.enable_subsidy_support &&
                subValue.title === '補助金申請支援サービス'
              ) {
                // 経営相談掲示板が無効
              } else if (
                !appStore.appConfig.enable_forum &&
                subValue.title === '経営相談掲示板'
              ) {
                // eslint-disable-next-line
                return false;
                // BA Mallが無効
              } else if (
                !appStore.appConfig.enable_mall &&
                subValue.title === 'お得な外部サービス'
              ) {
                return false;
              } else if (
                hasPermission(subValue.required) &&
                hasAppConfig(subValue.appConfigRequired)
              ) {
                sub.push(subValue);
              }
            });
            value.sub = sub;
            items.value.push(value);
          }
          // FUKURI
          if (value.title === '福利厚生') {
            if (
              (value.required &&
                value.required[0] === 'company_setting_coupon_write') ||
              userStore.loginUser.user_is_super_user
            ) {
              skipFukuri = true;
            }
          }
        }
      });
    };

    const transferFukuri = (): void => {
      openFukuri();
    };

    const transferPayment = (): void => {
      openPayment();
    };

    const hasPermission = (required: any): boolean => {
      if (!required) {
        return true;
      }

      return requirePermission(required);
    };

    const hasAppConfig = (appConfigRequired: any): boolean => {
      if (!appConfigRequired) {
        return true;
      }

      // 判定のためにappConfigに型を付与する
      const appConfig: StringKeyObject = appStore.appConfig;
      return appConfig[appConfigRequired] === 1;
    };

    const showIcnextDialog = ref<boolean>(false);
    const showIcnextApplicationModal = (): any => {
      // ローカルストレージにfalseで保存されていた場合はstoreの値をfalseで上書きする。
      if (localStorage.getItem('icnext_display_modal') === 'false') {
        setIcnextModal(false);
      }

      // icnextDisplayModalがfalseの場合は、ダイアログを表示せず別タブ遷移。
      if (icnextDisplayModal.value) {
        showIcnextDialog.value = !showIcnextDialog.value;
      } else {
        setIcnextModal(false);
        window.open(appStore.icnextApplicationUrl, '_blank');
      }
    };

    setOpenSC();
    setNavi();

    // メニューが選択されたらメニューを閉じる
    watch(
      () => route.value,
      () => {
        mobileNavi.value.isShow = false;
        spSettingDrawer.value = false;
      }
    );

    // サイドメニュー表示・非表示
    const toggleMenu = (): void => {
      if (device.value !== 'sp') {
        mobileNavi.value.isShow = false;
        drawer.value = !drawer.value;
      } else {
        mobileNavi.value.isShow = true;
      }
    };

    // いつクラウドファンディングを確認したかのフラグを追加(存在していたらnewバッジを出すか出さないかのを確認)
    const ctx = useContext();
    const showCampfireNewBadge = ref<boolean>(
      isShowCampfireNewBadge(
        ctx.$formatDateTime(new Date().toString(), 'YYYY-MM-DD HH:mm:ss')
      )
    );

    // いつ事業承継を確認したかのフラグを追加(存在していたらnewバッジを出すか出さないかのを確認)
    const showBusinessSuccessionNewBadge = ref<boolean>(
      isShowBusinessSuccessionNewBadge(
        ctx.$formatDateTime(new Date().toString(), 'YYYY-MM-DD HH:mm:ss')
      )
    );

    // いつ勤怠連携機能を確認したかのフラグを追加(存在していたらnewバッジを出すか出さないかのを確認)
    const showAttendanceLinkageNewBadge = ref<boolean>(
      isShowIcnextLinkageNewBadge(
        ctx.$formatDateTime(new Date().toString(), 'YYYY-MM-DD HH:mm:ss')
      )
    );

    const showPaymentNewBadge = ref<boolean>(
      isShowPaymentNewBadge(
        ctx.$formatDateTime(new Date().toString(), 'YYYY-MM-DD HH:mm:ss')
      )
    );

    /**
     * 指定されたパスにページ遷移を行う
     * @param path パス
     * @param isCloseDrawer ドロワーを閉じるかどうか（デフォルト：閉じない）
     */
    const navigateToPage = (path: string, isCloseDrawer = false): void => {
      drawer.value = !isCloseDrawer;
      router.push(path);
    };

    /**
     * 指定されたパスに同期的遷移を行う
     * @param path パス
     */
    const navigateByHref = (path: string): void => {
      window.location.href = path;
    };

    /**
     * 未読の総カウントを取得する
     */
    const totalUnreadCount = computed(() => {
      let total = ((bachatRoomsUnreadCount.value as number) +
        chatRoomsUnreadCount.value) as number;
      // 企業オーナーまたはビジネスユーザーの場合はご案内の未読数を加算する
      if (isAllowedRole.value) {
        total += unreadCmiCount.value as number;
      }
      return total;
    });

    /**
     * マッチングの未読の総カウントを取得する
     */
    const totalMatchingCount = computed(() => {
      let total = 0;
      // オーナーの場合はご案内の未読数を加算する
      if (isAllowedRole.value) {
        total += matchingUnrepliedCount.value as number;
      }
      return total;
    });

    // バッジに表示する未読数を取得する
    const getBadgeCount = (item: NavigationItem): number | undefined => {
      // 金融機関連携チャット
      if (item.path === '/mypage/bachat' && bachatRoomsUnreadCount.value > 0) {
        return bachatRoomsUnreadCount.value;
      }
      // ビジネスチャット
      if (item.path === '/chat' && chatRoomsUnreadCount.value > 0) {
        return chatRoomsUnreadCount.value;
      }
      // お知らせ・ご案内
      const userType = userStore.context.state.loginUser.user_type;
      if (
        (userType === 'company_owner' || userType === 'company_user' || userType === 'partner_company_owner') &&
        item.path === '/mypage/news' &&
        unreadCmiCount.value > 0
      ) {
        return unreadCmiCount.value;
      }
      // 商談管理
      if (
        item.path === '/mypage/matching/received_history' &&
        matchingUnrepliedCount.value > 0
      ) {
        return matchingUnrepliedCount.value;
      }
      return undefined;
    };

    // ユーザータイプを判定する。
    const isAllowedRole = computed(() => {
      const userType = userStore.context.state.loginUser.user_type;
      return userType === USER_TYPE.OWNER || userType === USER_TYPE.USER || userType === USER_TYPE.PARTNER_OWNER;
    });

    return {
      userStore,
      items,
      isAdmin,
      mobileNavi,
      unreadCmiCount,
      bachatRoomsUnreadCount,
      chatRoomsUnreadCount,
      matchingUnrepliedCount,
      openSC,
      appStore,
      transferFukuri,
      transferPayment,
      showCampfireNewBadge,
      drawer,
      showIcnextDialog,
      icnextDisplayModal,
      showIcnextApplicationModal,
      toggleMenu,
      device,
      showBusinessSuccessionNewBadge,
      adjustment,
      showAttendanceLinkageNewBadge,
      showPaymentNewBadge,
      currentPath,
      currentSubItems,
      isMainItemSelected,
      isSubItemSelected,
      mainNaviWidth,
      subNaviWidth,
      getSubItems,
      testBar,
      spSettingDrawer,
      navigateToPage,
      navigateByHref,
      currentHash,
      totalUnreadCount,
      totalMatchingCount,
      getBadgeCount
    };
  }
});
